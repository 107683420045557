import gql from "graphql-tag";

export const pageQuery = gql`
  query Pages($slug: String!) {
    pages(filters:{slug: {eq: $slug}}) {
      data {
        attributes {
          title
          modules {
            ... on ComponentModuleText {
              text
              textplacement: placement
            }
            ... on ComponentModuleColors {
              invert
            }
            ... on ComponentModuleEmbed {
              code
            }
            ... on ComponentModuleVideo {
              link
              cover {
                data {
                  attributes {
                    width
                    height
                    caption
                    mime
                    url
                    hash
                    ext
                  }
                }
              }
            }
            ... on ComponentModuleImages {
              imageplacement: placement
              type
              images {
                data {
                  attributes {
                    width
                    height
                    caption
                    mime
                    url
                    hash
                    ext
                  }
                }
              }
            }
            ... on ComponentModuleProjects {
              grid
              projects(sort: "date:DESC", pagination: {limit:200}){
                data {
                  attributes {
                    slug
                    title
                    headline
                    type {
                      data {
                        id
                      }
                    }
                    image {
                      data {
                        attributes {
                          width
                          height
                          caption
                          mime
                          url
                          hash
                          ext
                        }
                      }
                    }
                    video
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`